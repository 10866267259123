import { take } from 'lodash-es';

import { JiraConfiguration, SearchObjectOptions } from '@@core/models/helper.model';
import { environment } from '@@environments/environment';
import { BaseUserData } from '@@shared/stores/auth-store/models/auth-store.models';


export const environmentInit = (): void => {
	const envName = environment.production ? 'PRODUCTION' : 'DEVELOPMENT';
	const envMessage = `SeaLights Web App is running with ${envName} environment file`;
	const messageStyle = `
		background-color: #fdf3cf;
		border: 1px solid #673ab7;
		border-radius: 4px;
		color: #673ab7;
		font-family: georgia;
		font-size: 14px;
		font-weight: bold;
		margin: 8px;
		padding: 8px;
	`;

	console.log(`%c${envMessage}`, messageStyle);
};

const copyToClipboardDeprecated = (text: string): void => {
	const element = document.createElement('textarea');

	element.value = text;
	element.setAttribute('readonly', '');
	element.style.position = 'absolute';
	element.style.left = '-9999px';
	document.body.appendChild(element);
	element.select();
	try {
		document.execCommand('copy');
	} catch (error) {
		console.error(error);
	}
	document.body.removeChild(element);
};

export const copyToClipboard = async (text: string): Promise<void> => {
	if ('clipboard' in navigator) {
		await navigator.clipboard.writeText(text);
	} else {
		copyToClipboardDeprecated(text);
	}
};

export const downloadAsFile = (content: ArrayBuffer | string, name: string, type: string): void => {
	const a = document.createElement('a');
	const file = new Blob([content], { type });

	a.href = URL.createObjectURL(file);
	a.download = name;
	document.body.appendChild(a);		// Firefox needs the element to be in the page, not only in memory
	a.click();
	URL.revokeObjectURL(a.href);
	a.remove();
};


export const mailTo = (email: string): void => {
	const a = document.createElement('a');

	a.href = `mailto:${email}`;
	document.body.appendChild(a);		// Firefox needs the element to be in the page, not only in memory
	a.click();
	URL.revokeObjectURL(a.href);
	a.remove();
};

export const navigateInNewTab = (relativeUrl: string): void => {
	const fullUrl = `${location.protocol}//${location.host}${relativeUrl}`;
	window.open(fullUrl, '_blank');
};

export const hashWithSha256 = async (text: string | number): Promise<string> => {
	const buffer: ArrayBuffer = await window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(text?.toString?.()));
	const hashArray = Array.from(new Uint8Array(buffer)); // convert buffer to byte array
	const hashHex = hashArray
		.map((b) => b.toString(16).padStart(2, '0'))
		.join(''); // convert bytes to hex string
	return hashHex;
};

export const isChromeBrowser = (): boolean => {
	const userAgent = window.navigator.userAgent.toLowerCase();

	return userAgent.indexOf('chrome') > -1 && userAgent.indexOf('firefox') === -1 && userAgent.indexOf('edg') === -1;
};

/**
 * Return unique array of objects, where uniqueness is determined by a property in the top level of the object (not of an inner object)
 *
 * @param original original array of objects
 * @param property array property that determines uniqueness
 */
export const toUniqueArrayOfObjects = <T>(original: T[], property: string): T[] => {
	const result: T[] = [];
	const map = new Map();

	for (const o of original) {
		if (!map.has(o[property])) {
			map.set(o[property], true);
			result.push(o);
		}
	}

	return result;
};

export const searchObject = (o: object, searchTerm: string, options: SearchObjectOptions = defaultSearchObjectOptions): any[] => {
	const realValues = Object.values(o ?? {}).filter(value => value !== null && value !== undefined);
	let matches = [];

	if (!options.caseSensitive) {
		searchTerm = searchTerm.toLowerCase();
	}

	for (const value of realValues) {
		if (typeof value === 'object') {
			matches = matches.concat(searchObject(value, searchTerm));
		} else {
			const found = (value.toString().toLowerCase() as string).includes(searchTerm);

			if (found) {
				matches.push(value);
			}
		}
	}

	return matches;
};

const defaultSearchObjectOptions: SearchObjectOptions = {
	caseSensitive: false
};

/**
 * Convert list of items to string like for ex. 'a, b, c and 10 others'
 */
export const listToNameCluster = <T>(items: T[], itemsToTake: number, additionWords: [string, string], stringMapper: (item: T) => string): string => {
	// Item list is not an array or empty item list? Sorry, your mistake!
	if (!(Array.isArray(items) && items.length)) {
		return '';
	}

	// cannot take 0 or less. Default is 1
	if (itemsToTake <= 0) {
		itemsToTake = 1;
	}

	// This is kind of optional. Give a chance to replace 10 others with something like 10 other users or groups
	if (!(Array.isArray(additionWords) && additionWords.length === 2)) {
		additionWords = ['other', 'others'];
	}

	// Set singular and plural words
	const additionalWordSingular = additionWords[0];
	const additionalWordPlural = additionWords[1];

	// Take items
	const itemsTaken = take(items, itemsToTake);

	// Map the items to kind of array of strings by user defined way
	const mappedStrings = itemsTaken.map(stringMapper);

	// // If it is only one, return it.
	// if (mappedStrings.length === 1) {
	// 	return mappedStrings.toString();
	// }

	let result: string;

	// See how many items left to display.
	const otherItemsNumber = items.length - itemsToTake;

	// if more than 1 then display all item strings that user asked to take and add items left (plural variant)
	// If it is only 1 left, then display all item strings that user asked to take and add items left (singular variant)
	// Less than 1 left, display all item strings that user asked to take. No additional strings
	if (otherItemsNumber > 1) {
		result = mappedStrings.join(', ');
		result += ` and ${otherItemsNumber} ${additionalWordPlural}`;
	} else if (otherItemsNumber === 1) {
		result = mappedStrings.join(', ');
		result += ` and ${otherItemsNumber} ${additionalWordSingular}`;
	} else {
		if (mappedStrings.length === 1) {
			return mappedStrings.toString();
		}
		const allMappedStringsButLast = take(mappedStrings, mappedStrings.length - 1);
		result = `${allMappedStringsButLast.join(', ')} and ${mappedStrings[mappedStrings.length - 1]}`;
	}

	return result;
};

export const configureJiraWidget = (config: JiraConfiguration): void => {
	if (document.body.querySelector('script#slJiraWidgetScript')) {
		return;
	}

	const script = document.createElement('script');

	globalThis.ATL_JQ_PAGE_PROPS = {
		fieldValues: config.fieldValues
	};

	script.setAttribute('type', 'text/javascript');
	script.setAttribute('id', 'slJiraWidgetScript');
	script.setAttribute('src', 'https://sealights.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/cfqidq/b/14/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=5390140f');
	document.body.appendChild(script);
};

export const removeJiraWidget = (): void => {
	const slJiraWidgetScript = document.body.querySelector('script#slJiraWidgetScript');
	if (!slJiraWidgetScript) {
		return;
	}

	document.body.removeChild(slJiraWidgetScript);
};

export const registerIntercom = (userData: BaseUserData): void => {
	const w: any = window;
	const hostname = window.location.hostname;
	const skipIntercom = hostname === 'localhost' || hostname === '127.0.0.1' || /.dev.sealights.co$/.test(hostname);	// update Intercom with real users only

	if (userData && !skipIntercom) {
		w.intercomSettings = {
			app_id: 'q4faytq7',
			customerId: userData.customerId,
			name: userData.userName,
			email: userData.email,
			hide_default_launcher: true
		};
		if (w.Intercom) {
			w.Intercom('reattach_activator');
			w.Intercom('update', w.intercomSettings);
		} else {
			console.error('Intercom not available');
		}
	}
};

// TODO: this should not be here. Header/Cockpit specific. Temporary position until we have proper localStorage service/store
export const toggleHideSuperuserMessagesFlag = (): void => {
	if (localStorage.getItem('hide-superuser-messages')) {
		localStorage.removeItem('hide-superuser-messages');
	} else {
		localStorage.setItem('hide-superuser-messages', '1');
	}
};

// TODO: this should not be here. Header/Cockpit specific. Temporary position until we have proper localStorage service/store
export const getHideSuperuserMessagesFlag = (): boolean => localStorage.getItem('hide-superuser-messages') ? true : false;
